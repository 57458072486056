<!-- eslint-disable global-require -->
<template>

  <div class="add-employees">
    <div class="card-form">
    <h1 class="title">Join Carmen</h1>

<form  @submit.prevent="addEmployees">

  <div class="row">
    <div class="col">
      <label
			class="block mb-1 mx-5 text-left text-md text-primary-dark dark:text-primary-light"
			>Name
			</label>
      <input
      class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
       v-model="employeesForm.name"  type="text"/>
    </div>

    <div class="col">
      <label
			class="block mb-1 mx-5 text-left text-md text-primary-dark dark:text-primary-light"
			>Job Title
			</label>
      <input
      class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
         v-model="employeesForm.job_title"  type="text"/>
    </div>
  </div>



  <div class="row">
    <div class="col">
      <label
			class="block mb-1 mx-5 text-left text-md text-primary-dark dark:text-primary-light"
			>Moblie
			</label>
      <input
      class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
       v-model="employeesForm.mobile" type="text"  @input="restrictToNumbers"/>
    </div>

    <div class="col">
      <label
			class="block mb-1 mx-5 text-left text-md text-primary-dark dark:text-primary-light"
			>Email
			</label>
      <input
      class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
         v-model="employeesForm.email"  type="email"/>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <label
			class="block mb-1 mx-5 text-left text-md text-primary-dark dark:text-primary-light"
			>Linked In
			</label>
      <input
      class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
       v-model="employeesForm.linked_in"  type="url"/>
    </div>

    <!-- <div class="col">
      <label
			class="block mb-1 ml-5 text-left text-lg text-primary-dark dark:text-primary-light"
			>Whats App
			</label>
      <input
      class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
         v-model="employeesForm.whats_app"  type="number"/>
    </div> -->
      <div class="col">

    </div>

  </div>

  <!-- <div class="row">
    <div class="col">
      <label
			class="block mb-1 ml-5 text-left text-lg text-primary-dark dark:text-primary-light"
			>Address
			</label>
      <input
      class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
       v-model="employeesForm.address"  type="text"/>
    </div>

    <div class="col">
      <label
			class="block mb-1 ml-5 text-left text-lg text-primary-dark dark:text-primary-light"
			>Company Brief
			</label>
      <input
      class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
         v-model="employeesForm.company_brief"  type="text"/>
    </div>
  </div> -->



  <div class="row">
 <!-- <div class="col">
      <label
			class="block mb-1 ml-5 text-left text-lg text-primary-dark dark:text-primary-light"
			>Website Company
			</label>
      <input
      class="w-full px-5 py-3 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
         v-model="employeesForm.website_company"  type="url"/>
    </div> -->

    <div class="col  photo-input">
      <div class="ml-8">
        <img
                 id="previewImage"
                 class="img"
                 :src="employeesForm.photo"
                />
      </div>
  <div>
      <button class="upload-btn" type="button" @click="openFileInput">Upload profile pic</button>
                <input ref="fileInput" type="file" class="input-file" style="display: none;" @change="handleFileSelect"/>
              </div>
    </div>
  </div>

  <div v-if="error !== ''" class="my-10">
    <h3
    :class="`${responseState === 'successfully' ? 'error-success' : 'error'}`"
    >{{ error }} </h3>

  </div>

  <div class="aline-center mt-16 ">
    <button class="add-btn mb-16" type="submit" > save & welcome</button>

  </div>

</form>

    </div>

<loadingSpinner v-if="loading" class="loading"/>
  </div>
</template>

<script>

import axios from 'axios'
import loadingSpinner from '../components/reusable/LoadingSpannier.vue'

export default {
components:{
  loadingSpinner
},

  data() {
    return {
      token:'',
      error: '',
      responseState: '',
      loading: false,
      employeesForm: {
        name: '',
        job_title: '',
        mobile: '+',
        email: '',
        linked_in: '',
        website_company: '',
        address: '',
        company_brief: '',
        whats_app: '',
        photo : ''
      },

    }
  },
  methods: {

    restrictToNumbers(event) {
      // Remove non-numeric characters from the input value
      this.employeesForm.mobile = event.target.value.replace(/[^\d+]/g, '');
    },
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.employeesForm.photo = file;

      const reader = new FileReader();

      reader.onload = (e) => {
        const previewImage = document.getElementById('previewImage');
        previewImage.src = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },



    addEmployees() {
      this.loading = true;

      if(this.employeesForm.linked_in == ''){
         this.employeesForm.linked_in = 'https://www.linkedin.com/company/carmen-perfumes/'
      }else{
         this.employeesForm.linked_in
      }


      let formData = new FormData();
      
      formData.append('linked_in' , this.employeesForm.linked_in)
      formData.append('name' , this.employeesForm.name)
      formData.append('job_title' , this.employeesForm.job_title)
      formData.append('email' , this.employeesForm.email)
      formData.append('mobile' , this.employeesForm.mobile)
      formData.append('website_company' ,"https://carmenperfumes.ae")
      formData.append('address' ,"adress")
      formData.append('company_brief' , "brief")
      formData.append('whats_app' , this.employeesForm.mobile)
      formData.append('photo' , this.employeesForm.photo)



    axios.post(`https://vcard2.fci.group/api/employees`,  formData , {
      headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json, text/plain, */*',
      }
    })
    .then(res => {
  if (res.status === 200) {
    this.loading = false;
    this.responseState = 'successfully';
    this.error = "Welcome to Carmen. Please wait for the admin to verify your information. Your details will be sent to your Email."
    //wait 3 seconds before redirecting to the employee page
    this.employeesForm = {
        name: '',
        job_title: '',
        mobile: '+',
        email: '',
        linked_in: '',
        website_company: '',
        address: '',
        company_brief: '',
        whats_app: '',
        photo : ''
      }

  }
    }).catch(error => {
      this.loading = false;
      this.error = error.response.data.message ;
      console.error('Error adding employee:', error);
   });
    }
  },
}
</script>

<style scoped>
.add-employees{
position: absolute;
text-emphasis-color:hsl(226, 80%, 53%) ;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color:#f7f7f7;

/* background: white; */
}
.card-form{
background-color:#f7f7f7;

}

.title{
  font-size: 49px;
  margin: 10px

}
.row{
  display: flex;
  flex-wrap: nowrap;
  padding: 8px;
}
.col{
  width: 50%;
  margin: 8px;
}
.photo-input{
  display: flex;
  align-items: center;
  justify-content: start;
}
.upload-btn{
  background-color: #0564f2;
  color: #f7f7f7;
  margin-left: 10px;
  padding: 8px 28px;
  border-radius: 13px;
}
.upload-btn:hover{
  background-color: #195b99;
}
.add-btn{
  background-color: #103f86;
  color: #f7f7f7;
  margin-left: 10px;
  padding: 13px 38px;
  border-radius: 13px;
}
.add-btn:hover{
  background-color: #0a2853;

}
.input-file{
position: relative;
background-color: #0564f2;
}
.img{
  width: 150px;
  height: 100px;

}
.error-success{
  background-color:#388E3C;
  padding: 10px;
  border-radius: 18px;
  width: 50%;
  align-items: center;
  margin: auto;
  color: white;
  font-size: larger;
  box-shadow: 0 0 10px #388E3C

;
}
.error{
  background-color: #E53935
;
  padding: 10px;
  border-radius: 18px;
  width: 50%;
  align-items: center;
  margin: auto;
  color: white;
  font-size: larger;
  box-shadow: 0 0 10px #E53935;

}
.loading{
  position: fixed;
  top: 0%;
  background-color: antiquewhite;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .photo-input{
  display: flex;
  flex-direction: column;
}
.upload-btn{
  margin-top: 10px;
}
.row{
  flex-wrap: wrap;
}
.col{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

}
</style>