<template>
    <AppHeader />

  <section
    class="flex flex-col sm:justify-between items-center sm:flex-row mt-4 sm:mt-10"
  >
    <!-- Banner left contents -->
    <div class="w-full md:w-1/3 text-left">
      <h1
        class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-primary-dark dark:text-primary-light uppercase"
      >
        {{ employee.name }}
      </h1>
      <p
        class="font-general-medium mt-2 text-lg sm:text-xl xl:text-2xl text-center sm:text-left leading-none text-ternary-dark dark:text-ternary-light"
      >
        {{ employee.job_title }}
      </p>
    </div>

    <!-- Banner right illustration -->
    <div class="flex flex-wrap justify-center mt-4">
      <div class="conOfImg px-4">
        <img
         v-if="employee.photo === ''"
          src="../assets/images/avatr2.png"
          alt="..."
          class="max-w-full h-auto align-middle border-none"
        />
        <img
          v-if="employee.photo !== ''"
          :src="employee.photo"
          alt="..."
          class="max-w-full h-auto align-middle border-none"
        />

        <!-- <img
          :src="employee.photo"
          :style="`border: 2px solid ${
            theme === 'light' ? '#1c9bf6' : '#ffffff'
          }`"
          alt="..."
          class="shadow max-w-full h-auto align-middle border-none"
        />  -->
      </div>
    </div>

    <div
      class="pt-12 sm:pt-30 pb-6 mt-7 border-primary-light dark:border-secondary-light"
    >
      <!-- Footer social links -->
      <div class="flex flex-col justify-center items-center mb-11 mt-6 sm:mb-20">
        <p
          class="font-general-semibold text-2xl sm:text-4xl font-semibold text-primary-light dark:text-primary-dark mb-3"
        >
          CONTACT ME
        </p>
        <ul class="flex gap-4 sm:gap-8">
          <a
            :href="'tel:' + employee.mobile"
            target="__blank"
            class="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-2 h-10 mr-3 duration-500"
          >
            <i
              :data-feather="'phone'"
              :color="`${theme === 'light' ? '#7bd1c6' : '#ffffff'}`"
            ></i>
          </a>
          <a
            :href="'mailto:' + employee.email"
            target="__blank"
            class="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-2 h-10 mx-2 duration-500"
          >
            <i
              :data-feather="'mail'"
              :color="`${theme === 'light' ? '#7bd1c6' : '#ffffff'}`"
            ></i>
          </a>
          <a
            :href="employee.address"
            target="__blank"
            class="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 mx-2 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-2 h-10 duration-500"
          >
            <i
              :data-feather="'map-pin'"
              :color="`${theme === 'light' ? '#7bd1c6' : '#ffffff'}`"
            ></i>
          </a>
          <a
            :href="employee.website_company"
            target="__blank"
            class="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 mx-2 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-2 h-10 duration-500"
          >
            <i
              :data-feather="'globe'"
              :color="`${theme === 'light' ? '#7bd1c6' : '#ffffff'}`"
            ></i>
          </a>
          <a
            :href="'https://api.whatsapp.com/send?phone=' + employee.whats_app"
            target="__blank"
            class="text-gray-400 hover:text-indigo-500 ml-2 dark:hover:text-indigo-400 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-2 h-10 sduration-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              width="21"
              viewBox="0 0 448 512"
            >
              <path
                :fill="`${theme === 'light' ? '#7bd1c6' : '#ffffff'}`"
                d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
              />
              <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->

              <!-- Footer copyright -->
            </svg>
          </a>
        </ul>
      </div>
<button
class="text-xl  text-gray-400 hover:text-indigo-500 ml-2 dark:hover:text-indigo-400 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm px-4 h-10 sduration-500"
 @click="saveVCard">
<span   :style="`color : ${theme === 'light' ? '#7bd1c6' : '#ffffff'}`">Add To Contacts</span>
</button>

      <FooterCopyright />
    </div>
  </section>

</template>

<script>
import feather from "feather-icons";
import axios from "axios";
import FooterCopyright from "@/components/shared/FooterCopyright.vue";
import AppHeader from "../components/shared/AppHeader";
import vCardsJS from 'vcards-js';


export default {
  components: {
    AppHeader,
    FooterCopyright,

  },
  name: "Home",
  data: () => {
    return {
      theme: "",
      employee: [],
    };
  },
  created() {
    this.theme = localStorage.getItem("theme") || "light";
  },
  mounted() {
    feather.replace();
    this.theme = localStorage.getItem("theme") || "light";
    const employeeId = this.$route.params.id ;

    // Make a GET request to fetch data based on the employeeId
    this.fetchData(employeeId);


  },
  updated() {
    feather.replace();
  },
  methods: {

    saveVCard() {
  // Create a new vCard
  const vCard = vCardsJS();



// Assuming this.employee.name contains the full name
var fullName = this.employee.name;
// Split the full name into an array of names
var names = fullName.split(" ");

// Initialize variables for first, middle, and last names
var firstName = names[0];
var middleName = "";
var lastName = "";

// Check if there are more than two names
if (names.length > 2) {
    // If there are more than two names, assume the last one is the last name
    lastName = names.pop();
    // Join the remaining names as the middle name
    middleName = names.slice(1).join(" ");
} else if (names.length === 2) {
    // If there are exactly two names, assume the second one is the last name
    lastName = names[1];
} else {
    // If there's only one name, assume it's the first name
    firstName = names[0];
}



  // Set properties
  vCard.address = this.employee.address
  vCard.organization = 'Carmen Certification LLC';
  vCard.workPhone = this.employee.mobile;
  vCard.email = this.employee.email;
  vCard.title = this.employee.job_title;
  vCard.workUrl = this.employee.website_company;
  vCard.socialUrls['linkedIn'] = this.employee.linked_in;
  vCard.photo.attachFromUrl(this.employee.photo, 'JPE');




  vCard.firstName = firstName;
  vCard.middleName = middleName;
  vCard.lastName = lastName;



  // Get vCard as a formatted string
  const vCardData = vCard.getFormattedString();
console.log(vCardData)
  // Convert vCard data to a Blob
  const blob = new Blob([vCardData], { type: 'text/vcard' });
  // Create a temporary link element
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fullName.toLowerCase()}-carmen.vcf`);

  // Trigger download
  link.click();

  // Clean up
  window.URL.revokeObjectURL(url);
    },
    fetchData(employeeId) {
      // Replace the URL with the actual API endpoint you want to fetch data from
      axios
        .get(`https://vcard2.fci.group/api/employees/${employeeId}`)
        .then((response) => {
          this.employee = response.data.data.employee;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>

<style scoped>
.conOfImg {
  width: 200px;
  height: 180px;
}
.conOfImg img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border-radius: 45%;
}
</style>
